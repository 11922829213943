/**
 * main.ts
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Plugins
import { registerPlugins } from "@/plugins";

// Components
import App from "./App.vue";

import { Amplify } from "aws-amplify";
import awsmobile from "./aws-config";

// Composables
import { createApp } from "vue";
import ToastPlugin from "vue-toast-notification";
import "vue-toast-notification/dist/theme-bootstrap.css";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

Amplify.configure(awsmobile);

const app = createApp(App);

registerPlugins(app);
app.use(ToastPlugin);
app.use(VueSweetalert2);

app.mount("#app");
