import { fetchAuthSession } from "aws-amplify/auth";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";

const REGION = import.meta.env.VITE_APP_AWS_REGION;

export const uploadFileToS3 = async (file: File): Promise<string> => {
  const { credentials } = await fetchAuthSession();

  if (!credentials) throw new Error("No credentials found");

  const { accessKeyId, sessionToken, secretAccessKey } = credentials;

  console.log(
    "accessKeyId",
    accessKeyId,
    "secretAccessKey",
    secretAccessKey,
    "sessionToken",
    sessionToken
  );

  const s3Client = new S3Client({
    credentials: {
      accessKeyId: accessKeyId,
      secretAccessKey: secretAccessKey,
      sessionToken: sessionToken,
    },
    region: REGION,
  });

  const params = {
    Bucket: import.meta.env.VITE_APP_S3_BUCKET_NAME,
    Key: file.name,
    Body: file,
  };
  console.log("params", params);
  const command = new PutObjectCommand(params);
  try {
    await s3Client.send(command);
    // console.log("Success", data);
    const cloudFrontUrl = `${import.meta.env.VITE_APP_CLOUDFRONT_DOMAIN}${
      file.name
    }`;
    return cloudFrontUrl;
  } catch (err) {
    console.log("Error", err);
    throw err;
  }
};
