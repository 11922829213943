// Utilities
import { defineStore } from "pinia";
import { Blog } from "@/service/api";

// import { fetchBlogs } from "@/service/api";

export const useAppStore = defineStore("app", {
  state: () => ({
    isLoading: false,
    userName: "",
    blogCount: 15,
    blogs: [] as Blog[],
    displayNextPageButton: false,
    displayPreviousPageButton: false,
    lastEvaluatedKey: {},
    previousLastEvaluatedKey: {
      1: {},
    } as any,
    currentPage: 0,
  }),
  getters: {
    getBlogs: (state) => state.blogs,
    isAppLoading: (state) => state.isLoading,
  },
  actions: {},
});
