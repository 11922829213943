<!-- eslint-disable vue/script-indent -->
<template>
  <div>
    <div
      class="d-flex align-center justify-space-between ma-4 fixed-buttons"
      v-if="store.displayPreviousPageButton && store.displayNextPageButton"
    >
      <v-btn
        color="secondary"
        v-if="store.displayPreviousPageButton"
        @click="fetchPBlogs"
      >
        Previous
      </v-btn>

      <v-btn
        v-if="store.displayNextPageButton"
        color="secondary"
        @click="fetchNextBlogs"
      >
        Next
      </v-btn>
    </div>
    <div
      class="d-flex align-center justify-start ma-4 fixed-buttons"
      v-if="store.displayPreviousPageButton && !store.displayNextPageButton"
    >
      <v-btn
        color="secondary"
        v-if="store.displayPreviousPageButton"
        @click="fetchPBlogs"
      >
        Previous
      </v-btn>
    </div>
    <div
      class="d-flex align-center justify-end ma-4 fixed-buttons"
      v-if="store.displayNextPageButton && !store.displayPreviousPageButton"
    >
      <v-btn
        v-if="store.displayNextPageButton"
        color="secondary"
        @click="fetchNextBlogs"
      >
        Next
      </v-btn>
    </div>
    <div>
      <v-fab
        color="blue"
        icon="mdi-plus"
        class="ms-0 mb-15"
        location="bottom end"
        size="60"
        app
        appear
        @click="goToCreateBlog"
      ></v-fab>
    </div>
    <div class="d-flex flex-wrap justify-center">
      <v-card v-if="store.isAppLoading" elevation="0">
        <v-progress-circular indeterminate :size="100"> </v-progress-circular>
      </v-card>
      <v-card
        v-else
        v-for="blog in store.getBlogs"
        :key="blog.postId"
        class="ma-2 pa-2"
        width="300"
      >
        <v-img
          class="align-end text-white"
          fit
          height="200"
          :src="thumbNailUrl(blog.media)"
        >
          <div
            class="text-h6 mx-2"
            style="background-color: lightgrey; color: black"
          >
            {{ blog.title }}
          </div>
        </v-img>

        <v-card-actions class="text-caption d-flex justify-space-between">
          <div>
            <div>Author: {{ blog.author }}</div>
            <div v-if="showUpdatedOn(blog.createdOn, blog.updatedOn)">
              Updated On:
              {{ localTimeString(blog.updatedOn) }}
            </div>
            <div v-else>
              Created On: {{ localTimeString(blog.createdOn) }} <br />
            </div>
          </div>
          <div>
            <v-btn @click="editBlog(blog.postId)" icon>
              <v-icon> mdi-pencil </v-icon>
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import moment from "moment";

import { useAppStore } from "@/stores/app";
import { fetchBlogs, fetchPreviousBlogs } from "@/service/api";

export default defineComponent({
  setup() {
    const store = useAppStore();

    const router = useRouter();

    const goToCreateBlog = () => {
      router.push({ path: "/createBlog" });
    };

    const editBlog = (postId: string) => {
      router.push({ path: "/createBlog", query: { postId } });
    };

    const thumbNailUrl = (path: string) => {
      const cloudFrontUrl = `${
        import.meta.env.VITE_APP_CLOUDFRONT_DOMAIN
      }${path}`;

      return cloudFrontUrl;
    };

    return {
      store,
      goToCreateBlog,
      editBlog,
      thumbNailUrl,
    };
  },
  data() {
    return {};
  },
  methods: {
    localTimeString(dateTimeString: string) {
      return moment(dateTimeString).local().format("YYYY-MM-DD HH:mm");
    },
    showUpdatedOn(createdOn: string, updatedOn?: string): boolean {
      // If updatedOn is not provided, default it to the same value as createdOn
      updatedOn = updatedOn || createdOn;

      // Parse the dates using moment
      const createdDate = moment(createdOn);
      const updatedDate = moment(updatedOn);

      // Compare the dates
      return updatedDate.isAfter(createdDate);
    },
    async fetchNextBlogs() {
      await fetchBlogs();
    },
    async fetchPBlogs() {
      await fetchPreviousBlogs();
    },
  },
  async mounted() {
    await fetchBlogs();
  },
});
</script>

<style scoped>
.fixed-buttons {
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  padding: 0 20px;
  z-index: 1000; /* Ensure it appears above other content */
}
</style>
