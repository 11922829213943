<!-- eslint-disable vue/script-indent -->
<script setup lang="ts">
import { onMounted, reactive, ref } from "vue";

import {
  FontSize,
  VuetifyTiptap,
  // VuetifyViewer,
  // type ToolbarType,
  // type StarterKitOptions,
  // locale,
} from "vuetify-pro-tiptap";

import "vuetify-pro-tiptap/style.css";
import {
  BaseKit,
  Bold,
  Italic,
  Underline,
  Strike,
  Color,
  Highlight,
  Heading,
  Link,
  Image,
  // ImageOptions,
  // Video,
  Table,
  // Fullscreen,
  History,
} from "vuetify-pro-tiptap";

import { ulid } from "ulidx";
import { useAppStore } from "@/stores/app";
import { useRoute, useRouter } from "vue-router";

import moment from "moment";
import { Blog } from "@/service/api";
import { createBlog, getBlog, deleteBlogApi } from "@/service/api";
import { useToast } from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";

import Swal from "sweetalert2";

import { uploadFileToS3 } from "@/service/s3";
import logger from "@/utilities/logger";

const $toast = useToast();

const store = useAppStore();
const route = useRoute();
const router = useRouter();

const extensions = [
  BaseKit.configure({
    placeholder: {
      placeholder: "Enter some text...",
    },
  }),
  FontSize,
  Bold,
  Italic,
  Underline,
  Strike,
  Color,
  Highlight,
  Heading,
  Link,
  Image.configure({
    upload: uploadFileToS3,
  }),

  // Video,
  Table,
  // Fullscreen,
  History,
];

const blogMetadata = reactive({
  postId: "",
  title: "",
  description: "",
  content: "",
  author: "",
  media: "",
  createdOn: "",
  updatedOn: "",
});

const isSaving = ref(false);

let isCreatePost = ref(true);

const goToHome = () => {
  router.push("/");
};

let thumbNail = ref([] as File[]);
let isThumbNailUploading = ref(false);

const uploadThumbNail = async () => {
  isThumbNailUploading.value = true;
  try {
    console.log(thumbNail.value[0]);
    let file = thumbNail.value[0];

    await uploadFileToS3(file);

    blogMetadata.media = file.name;
  } catch (error) {
    $toast.error("There was some Error. Try again later.", {
      position: "top-right",
    });
  } finally {
    isThumbNailUploading.value = false;
  }
};

const save = async () => {
  isSaving.value = true;
  let blog: Blog;
  if (isCreatePost.value) {
    if (thumbNail.value.length == 0) {
      $toast.error("ThumbNail Not uploaded.", { position: "top-right" });
      isSaving.value = false;
    }
    const postId = ulid();
    blog = {
      postId,
      title: blogMetadata.title,
      description: blogMetadata.description,
      content: blogMetadata.content,
      author: store.userName,
      media: thumbNail.value[0].name,
      createdOn: moment().toISOString(),
      updatedOn: moment().toISOString(),
    };
  } else {
    (blogMetadata.updatedOn = moment().toISOString()), (blog = blogMetadata);
  }

  // if title, content and descrption  are empty then return
  if (!blog.title || !blog.description || !blog.content) {
    console.log("Title, description, and content must not be empty");
    isSaving.value = false;
    return;
  }

  console.log(blog);
  const res = await createBlog(blog);
  if (res) {
    $toast.success("Blog Created Successfully", {
      position: "top-right",
    });
    router.push("/");
  } else {
    $toast.error("There was some Error. Try again later.", {
      position: "top-right",
    });
  }
  isSaving.value = false;
};

const deleteBlog = () => {
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then(async (result: any) => {
    if (result.isConfirmed) {
      try {
        await deleteBlogApi(blogMetadata.postId);
        $toast.success("Deleted Successfully");
        goToHome();
      } catch (error) {
        logger.error("Error in delete Blog", error);
        $toast.error("There was some error. Try again later");
      }
    }
  });
};

onMounted(async () => {
  // check if post id is passed in router
  const postId = route.query.postId as string;
  console.log("postId: ", postId);

  if (postId) {
    try {
      isCreatePost.value = false;
      const res = await getBlog(postId);
      Object.assign(blogMetadata, res);
    } catch (error) {
      console.error("error", error);
      $toast.error("Error in fetching Blog. Try again later.");
    }
  }
});
</script>

<!-- <script lang="ts">
import { deleteBlogApi } from "@/service/api";



export default {
  methods: {
    deleteBlog() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result: any) => {
        if (result.isConfirmed) {
          try {
            const postId = this.$route.query.postId;

            if (!postId) {
              this.$toast.error("There was some error. Try again later");
              return;
            }

            await deleteBlogApi(postId as string);
            this.$toast.success("Deleted Successfully");
            // goToHome();
            this.$router.push("/");
          } catch (error) {
            logger.error("Error in delete Blog", error);
            this.$toast.error("There was some error. Try again later");
          }
        }
      });
    },
  },
};
</script> -->

<template>
  <div>
    <v-card
      v-if="store.isAppLoading"
      elevation="0"
      class="d-flex justify-center align-center"
    >
      <v-progress-circular indeterminate :size="100"> </v-progress-circular>
    </v-card>
    <v-container v-else>
      <div class="mb-6 d-flex justify-space-between">
        <v-btn icon variant="flat" @click="goToHome">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-btn icon variant="flat" v-if="!isCreatePost" @click="deleteBlog">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </div>
      <v-text-field
        v-model="blogMetadata.title"
        label="Title"
        variant="outlined"
      />

      <v-text-field
        v-model="blogMetadata.description"
        label="Description"
        variant="outlined"
      />

      <v-file-input
        v-model="thumbNail"
        accept="image/*"
        label="Cover Image"
        variant="outlined"
        prepend-icon=""
        append-icon="mdi-cloud-upload-outline"
        @click:append="uploadThumbNail"
        clearable
        chips
        :loading="isThumbNailUploading"
      ></v-file-input>

      <VuetifyTiptap
        v-model="blogMetadata.content"
        :extensions="extensions"
        label="Content"
        rounded
        max-height="500"
      />
      <!-- <VuetifyViewer :value="blogMetadata.content" /> -->

      <v-btn
        v-if="!isSaving"
        class="fixed-element"
        icon
        size="large"
        @click="save"
      >
        <v-icon size="large"> mdi-content-save-check-outline </v-icon>
      </v-btn>

      <v-btn v-else class="fixed-element" icon size="large">
        <v-progress-circular indeterminate />
      </v-btn>
    </v-container>
  </div>
</template>

<style scoped>
.fixed-element {
  position: fixed;
  bottom: 50px;
  right: 50px;
  padding: 10px;
}
</style>
