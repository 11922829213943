/* eslint-disable no-console */

const isProduction: boolean = process.env.NODE_ENV === "production";

const log = (message: string, ...args: unknown[]): void => {
  if (!isProduction) console.log(message, ...args);
};

const error = (err: Error | string, ...args: unknown[]): void => {
  if (!isProduction) console.error(err, ...args);
};

export default { log, error };
