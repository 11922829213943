/**
 * router/index.ts
 *
 * Automatic routes for `./src/pages/*.vue`
 */

import { createRouter, createWebHistory } from "vue-router";
import {
  getCurrentUser,
  signInWithRedirect,
  fetchUserAttributes,
  fetchAuthSession,
} from "aws-amplify/auth";

import logger from "@/utilities/logger";

import { useAppStore } from "@/stores/app";

import index from "@/pages/index.vue";
import createBlog from "@/pages/createBlog.vue";
import axios from "axios";

axios.interceptors.request.use(
  async function (config) {
    const { tokens } = await fetchAuthSession();

    if (!tokens) {
      return config;
    }

    config.headers["Authorization"] = `Bearer ${tokens.idToken}`;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

const routes = [
  {
    path: "/",
    component: index,
    meta: { requiresAuth: true },
  },
  {
    path: "/createBlog",
    component: createBlog,
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

// Workaround for https://github.com/vitejs/vite/issues/11804
router.onError((err, to) => {
  if (err?.message?.includes?.("Failed to fetch dynamically imported module")) {
    if (!localStorage.getItem("vuetify:dynamic-reload")) {
      console.log("Reloading page to fix dynamic import error");
      localStorage.setItem("vuetify:dynamic-reload", "true");
      location.assign(to.fullPath);
    } else {
      console.error("Dynamic import error, reloading page did not fix it", err);
    }
  } else {
    console.error(err);
  }
});

router.isReady().then(() => {
  localStorage.removeItem("vuetify:dynamic-reload");
});

/**
 * Authentication Guard for routes with requiresAuth metadata
 *
 * @param {Object} to - Intended route navigation
 * @param {Object} from - Previous route navigation
 * @param {Object} next - Next route navigation
 * @returns {Object} next - Next route
 */

router.beforeEach(async (to, from, next) => {
  // scroll to top of page

  window.scrollTo(0, 0);
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    try {
      logger.log("fetchAuthSession");
      const { username } = await getCurrentUser();

      const userAttributes = await fetchUserAttributes();
      logger.log("userAttributes", userAttributes);
      logger.log("username", username);

      const appStore = useAppStore();
      appStore.userName = username;
    } catch (error) {
      logger.error("auth error: ", error);
      signInWithRedirect();
    }
  }
  next();
});

export default router;
