<template>
  <v-app-bar :elevation="2">
    <!-- <template v-slot:prepend>
      <v-app-bar-nav-icon />
    </template> -->

    <v-app-bar-title @click="goToHome" style="cursor: pointer"
      >Blog Admin</v-app-bar-title
    >

    <v-btn icon color="primary">
      <v-icon>mdi-account-circle-outline</v-icon>
      <v-menu activator="parent">
        <v-list>
          <v-list-item @click="handleSignOut">
            <v-list-item-title>Sign Out</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-btn>
  </v-app-bar>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { signOut } from "aws-amplify/auth";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const router = useRouter();

    const goToHome = () => {
      router.push("/");
    };
    const handleSignOut = async () => {
      await signOut();
      goToHome();
    };
    return { handleSignOut, goToHome };
  },
});
</script>
