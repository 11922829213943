import axios from "axios";

import { useAppStore } from "@/stores/app";
import logger from "@/utilities/logger";

export interface Blog {
  postId: string;
  author: string;
  title: string;
  description: string;
  content: string;
  media: string;
  createdOn: string;
  updatedOn: string;
}

interface ApiResponse {
  blogs: Blog[];
  lastEvaluatedKey: {
    PK: string;
    SK: string;
  };
}

export async function fetchBlogs(): Promise<ApiResponse> {
  const store = useAppStore();
  store.isLoading = true;
  try {
    const count = store.blogCount;

    const lastKey = store.lastEvaluatedKey;

    let url = `${import.meta.env.VITE_APP_API}blogs?count=${count}`;

    console.log("lastKey", Object.keys(lastKey));

    if (Object.keys(lastKey).length != 0) {
      store.previousLastEvaluatedKey[store.currentPage] = lastKey;
      store.displayPreviousPageButton = true;
      url = url + `&lastKey=${encodeURIComponent(JSON.stringify(lastKey))}`;
    }

    const response = await axios.get<ApiResponse>(url);
    // push blogs to store blog and set lastEvaluatedKey
    store.currentPage = store.currentPage + 1;

    store.blogs = response.data.blogs;
    const lastEvaluatedKey = response.data.lastEvaluatedKey;
    if (lastEvaluatedKey) {
      store.lastEvaluatedKey = lastEvaluatedKey;
      store.displayNextPageButton = true;
    } else {
      store.displayNextPageButton = false;
    }
    store.isLoading = false;
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Error fetching data:", error.message);
    } else {
      console.error("Unexpected error:", error);
    }
    store.isLoading = false;
    throw error;
  }
}

export async function fetchPreviousBlogs(): Promise<ApiResponse> {
  const store = useAppStore();
  store.isLoading = true;
  try {
    const count = store.blogCount;
    const currentPage = store.currentPage;

    console.log("currentPage", currentPage);

    let previousPage = currentPage - 2;

    console.log("previousPage", previousPage);

    let lastKey = {};
    if (previousPage != 0) {
      lastKey = store.previousLastEvaluatedKey[previousPage];
      console.log("lastKey", lastKey);
    }

    let url = `${import.meta.env.VITE_APP_API}blogs?count=${count}`;
    if (Object.keys(lastKey).length != 0) {
      url = url + `&lastKey=${encodeURIComponent(JSON.stringify(lastKey))}`;
    }

    const response = await axios.get<ApiResponse>(url);

    store.currentPage = store.currentPage - 1;

    store.blogs = response.data.blogs;
    const lastEvaluatedKey = response.data.lastEvaluatedKey;
    if (lastEvaluatedKey) {
      store.lastEvaluatedKey = lastEvaluatedKey;
      store.displayNextPageButton = true;
    }
    if (store.currentPage == 1) {
      store.displayPreviousPageButton = false;
    }
    store.isLoading = false;
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Error fetching data:", error.message);
    } else {
      console.error("Unexpected error:", error);
    }
    store.isLoading = false;
    throw error;
  }
}

export async function createBlog(blog: Blog) {
  try {
    // const store = useAppStore();
    // const count = store.blogCount;
    const url = `${import.meta.env.VITE_APP_API}blog`;
    const response = await axios.post(url, blog);
    logger.log("response", response);
    return true;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Error fetching data:", error.message);
    } else {
      console.error("Unexpected error:", error);
    }
    return false;
  }
}

export async function getBlog(blogId: string): Promise<Blog> {
  const store = useAppStore();
  store.isLoading = true;
  try {
    const url = `${import.meta.env.VITE_APP_API}blog/${blogId}`;
    const response = await axios.get<Blog>(url);
    store.isLoading = false;
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Error fetching data:", error.message);
    } else {
      console.error("Unexpected error:", error);
    }
    store.isLoading = false;
    throw error;
  }
}

export async function deleteBlogApi(blogId: string) {
  const store = useAppStore();
  store.isLoading = true;
  try {
    const url = `${import.meta.env.VITE_APP_API}blog/${blogId}`;
    const response = await axios.delete(url);
    store.isLoading = false;
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Error fetching data:", error.message);
    } else {
      console.error("Unexpected error:", error);
    }
    store.isLoading = false;
    throw error;
  }
}
